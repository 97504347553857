import * as React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: center;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }
`

const about = () => {
  return (
    <>
      <Seo title="Śpiewający DJ - Perfekcyjny Kompromis Dla Niezdecydowanych Klientów Memento Group" />
      <Layout>
        <SimpleBanner
          title="Śpiewający DJ & Live ACT"
        >
          <StaticImage
            className="banner__image desktop"
            src="../images/dj/dj2.jpg"
          />
          <StaticImage
            className="banner__image mobile"
            src="../images/dj/dj2.jpg"
          />
        </SimpleBanner>
        <Container>
          <h2>
            Perfekcyjny Kompromis Dla Niezdecydowanych Klientów Memento Group
          </h2>
          <p>
            W Memento Group zawsze stawiamy na wyjątkowość i doskonałość w
            organizacji wydarzeń. Dla naszych Klientów, którzy stoją przed
            dylematem: DJ czy Zespół Muzyczny, prezentujemy znakomite
            rozwiązanie - Śpiewającego DJ-a, który będzie najlepszym kompromisem
            między obiema opcjami!
          </p>
          <h2>Dlaczego Śpiewający DJ z Memento Group?</h2>
          <h3>Połączenie Energii DJ-a z charyzmą Wokalisty:</h3>
          <p>
            Nasz Śpiewający DJ doskonale łączy w sobie umiejętności DJ-a i
            wokalisty. To unikalne połączenie energii i profesjonalizmu, które
            sprawi, że Wasze przyjęcie będzie niezapomniane.
          </p>
          <h3>Wszechstronność Repertuaru:</h3>
          <p>
            Bez względu na to, czy marzycie o najnowszych hitach czy klasycznych
            przebojach zespołów muzycznych, nasz Śpiewający DJ dostosuje się do
            Waszych preferencji, oferując szeroki repertuar, który zadowoli
            każdego gościa.
          </p>
          <h3>Zachowanie Autentyczności Live Performansu:</h3>
          <p>
            Chcemy, abyście doświadczyli prawdziwie live performance. Nasz
            Śpiewający DJ śpiewa na żywo, co dodaje wyjątkowego charakteru i
            autentyczności każdemu utworowi.
          </p>
          <h3>Dopasowanie Do Rozmiaru Imprezy:</h3>
          <p>
            Czy to kameralne przyjęcie czy impreza na dużą skalę, Śpiewający DJ
            dostosuje się do charakteru wydarzenia, zachowując przy tym
            najwyższą jakość występu.
          </p>
          <span>○○</span>
          <p>
            Zapraszamy do skorzystania z naszej wyjątkowej oferty Śpiewającego
            DJ-a i odkrycie,<br />
            jak Memento Group kreuje wydarzenia na najwyższym
            poziomie!
          </p>

          <span>♡ Memento Group ♡</span>
        </Container>
      </Layout>
    </>
  )
}

export default about
